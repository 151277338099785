<!-- RIBBON -->
<div class="accordion wizard" id="accordionExpandWizard">
  <div  class="card styleBackground" style="border-radius: 10px;border: 1px solid #6666 !important;">
      <ng-container>
          <div class="card-header" >
              <h2 class="mb-0">
                  <div class="row">
                      <div class="col-sm-12">
                          <div class="form-check pl-0 pb-2">
                              <i class=""
                                  style="font-size: 15px"></i>
                              <label class="accordianClass "
                                  data-target="#collapseOne1"
                                  aria-expanded="true"
                                  for="collapseOne1"
                                   data-toggle="collapse"
                                  style="font-size: 15px; cursor: pointer;"
                                  (click)=toggleWizardIcon(item)>{{'wizard_setup'| i18n}}</label>
                          </div>
                      </div>
                      <span class="collapsed-hidden plusBtn fal fa-minus fs-xl accordianClass wizard-label"
                          data-toggle="collapse"
                          data-target="#collapseOne1"
                          aria-expanded="true"
                          #item
                          (click)=toggleWizardIcon(item)>
                      </span>
                  </div>
              </h2>
          </div>
          <ng-container>
              <div id="collapseOne1" class="collapse accordianXpand show" data-parent="#accordionExpandWizard" >
                  <div class="row" >                                                        
                      <div class="card-body" >
                          <ng-container>
                            <div id="wizard" *ngIf="!loader">
                              <div class="wizard-container mx-0 w-100">
                                <!-- <div class="connecting-line"></div> -->
                                <div class="wizard-items " (click)="navigateWizardItem('interfaces')" [ngClass]="url=='interfaces'?'green-border' : ''">
                                    <div class="row p-3 mx-0 w-100">
                                        <div class="wizardCircleWidth d-flex align-items-center ">
                                          <div class="wizardDiv ml-xl-3"> <span>1</span>
                                            <b class="badges bg-color-green" *ngIf="showIcon&&count?.interface">
                                              <i class="fa fa-check" aria-hidden="true"></i>
                                            </b>
                                            <b class="badges bg-color-red" *ngIf="showIcon&&!count?.interface">
                                              <i class="fa fa-lg fa-close" aria-hidden="true"></i>
                                            </b>                                            
                                          </div>
                                        </div>
                                        <div class="wizardTextWidth">
                                          <div class="font-size-14">
                                            <span class="userDash_tileHeadgreen userDash_tileText">
                                              <b>{{'routerCommunication' | i18n}}</b>
                                            </span><br>
                                              <span class="userDash_tileHeadgreen userDash_tileText" [innerHTML]="communicationMsg">
                                                
                                              </span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line">
                                  <div class="line-1" [ngClass]="url=='interfaces'?'bg-color-green' : ''"></div>
                                </div>
                                <div class="wizard-items " (click)="navigateWizardItem('connector')" [ngClass]="url=='connector'?'green-border' : ''">
                                  <div class="row p-3 mx-0 w-100">
                                      <div class="wizardCircleWidth d-flex align-items-center ">
                                        <div class="wizardDiv ml-xl-3 userDash_tileText"> <span>2</span>
                                          <b class="badges bg-color-green" *ngIf="showIcon&&count?.connector">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                          </b>
                                          <b class="badges bg-color-red" *ngIf="showIcon&&!count?.connector">
                                            <i class="fa fa-lg fa-close" aria-hidden="true"></i>
                                          </b> 
                                        </div>
                                      </div>
                                      <div class="wizardTextWidth">
                                        <div class="font-size-14">
                                          <span class="userDash_tileHeadgreen userDash_tileText">
                                            <b>{{'routerBusinessConfigurations' | i18n}}</b>
                                          </span><br>
                                            <span class="userDash_tileHeadgreen userDash_tileText" [innerHTML]="configurationsMsg">
                                             
                                            </span>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div class="line">
                                  <div class="line-1" [ngClass]="url=='connector'?'bg-color-green' : ''"></div>
                                </div>                                
                                <div class="wizard-items "  (click)="navigateWizardItem('bcManagement')" [ngClass]="url=='bcManagement'?'green-border' : ''">
                                  <div class="row p-3 mx-0 w-100">
                                      <div class="wizardCircleWidth  d-flex align-items-center ">
                                        <div class="wizardDiv ml-xl-3"> <span>3</span>
                                          <b class="badges bg-color-green" *ngIf="showIcon&&count?.bc">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                          </b>
                                          <b class="badges bg-color-red" *ngIf="showIcon&&!count?.bc">
                                            <i class="fa fa-lg fa-close" aria-hidden="true"></i>
                                          </b> 
                                        </div>
                                      </div>
                                      <div class="wizardTextWidth">
                                        <div class="font-size-14">
                                          <span class="userDash_tileHeadgreen userDash_tileText">
                                            <b>{{'routerBusinessConnections' | i18n}}</b>
                                          </span><br>
                                            <span class="userDash_tileHeadgreen userDash_tileText" [innerHTML]="connectionsMsg">
                                              
                                            </span>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                              

                          </ng-container>
                      </div>
                  </div>
              </div>
          </ng-container>
      </ng-container>
  </div>
</div>
