import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { AppState } from '@app/app.service';
import {LayoutService} from "@app/core/services/layout.service";
import { TutorialEventsService } from '@app/core/services/tutorial-events.service';
import { CommonService } from '@app/shared/common/common.service';
import { I18nService } from '@app/shared/i18n';
import { EmitService } from '@app/ts/services/emit.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { WizardService } from './wizard.service';

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',

})
export class WizardComponent implements OnInit {

  count: any = {}
  url: string;
  loader: boolean;
  wizardSub: Subscription;
  first: boolean = true;
  showIcon: boolean = false;
  communicationMsg: any;
  configurationsMsg: any;
  connectionsMsg: any;
  communicationUrl: Promise<boolean>;
  configurationsUrl: Promise<boolean>;
  connectionsUrl: Promise<boolean>;
  langChangeSubscription: any;
  lanId: string;
  langaugeChanged: boolean = false;
  constructor(private layoutService: LayoutService,private router: Router,private route: ActivatedRoute,private emiterService: EmitService,
    private i18nService: I18nService,private wizardService: WizardService,private commonService: CommonService) {
      this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
    this.url = "" ;
    let currentUrl = this.router.url;
    this.setUrl(currentUrl);
  }
  bc: true
  connector: true
  interface: true
  ngOnInit() {
    this.loadCounts();
    let currentUrl;
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        currentUrl = val.url;
        this.setUrl(currentUrl);
      }
    });
    this.wizardSub = this.emiterService.refreshWizardDataSubject.subscribe( data => {
      if( data ){
        this.showIcon = false;
        this.loadCounts();
      }
      setTimeout(() => {
        this.loadTranslation();
      });      
    });

    this.emiterService.langSubject.subscribe( data => {
      if( data){
        this.loadTranslation();
      }
    })


  }
onLangChange(langObj: any) {
  langObj.isHandled = true;
  if( this.langaugeChanged ){
      setTimeout(() => {
        this.lanId = this.commonService.getCurrentLanguageCode();
        this.loadTranslation();
      },1000);
      
  }
  
  this.langaugeChanged = true;
}
  ngOnDestroy(): void {
    this.wizardSub ? this.wizardSub.unsubscribe() : "";
    
  }

  setUrl(currentUrl){
    if( currentUrl.includes("bcManagement")) this.url = 'bcManagement';
    if( currentUrl.includes("connector") ) this.url = 'connector'
    if( currentUrl.includes("interfaces") ) this.url = 'interfaces'
  }
  loadCounts(){
    let formData = new FormData();
    formData.append('netcom_id',localStorage.getEncryptedItem('netcomId'));
    this.wizardService.getCounts(formData).subscribe( data => {
      this.count = data["data"];
      this.showIcon = true;
    })
 
  }

  resetWidgets() {
    this.layoutService.factoryReset()
  }
  toggleWizardIcon(eve) {
    setTimeout(() => {
      if( eve.classList.contains('wizard-label')){
        if (eve.classList.contains('fa-plus')) {
          let btns = document.querySelectorAll('.wizard-label.fa-minus')
          btns != undefined ? btns.forEach(btn => {
            btn.classList.remove('fa-minus');
            btn.classList.add('fa-plus')
          }) : "";
          eve.classList.remove('fa-plus');
          eve.classList.add('fa-minus');      
        }
        else if (eve.classList.contains("fa-minus")) {
          eve.classList.remove('fa-minus');
          eve.classList.add('fa-plus');
        }
      }
      else{
        return
      }
    });    
  }

  navigateWizardItem(location){
    // $('.wizard-items').removeClass('green-border');
    this.router.navigate(['settings/'+location]);
  }
  loadTranslation(){
    setTimeout(() => {
      this.communicationMsg = this.i18nService.getTranslation('wizard_communication_msg').replace('$here$', '<span class="green-label" href="'+this.communicationUrl+'" >'+this.i18nService.getTranslation('bcManagement_here')+'</span>');
    this.configurationsMsg = this.i18nService.getTranslation('wizard_configurations_msg').replace('$here$', '<span class="green-label" href="'+this.configurationsUrl+'" >'+this.i18nService.getTranslation('bcManagement_here')+'</span>');
    this.connectionsMsg  = this.i18nService.getTranslation('wizard_connections_msg').replace('$here$', '<span class="green-label" href="'+ this.connectionsUrl+'">'+this.i18nService.getTranslation('bcManagement_here')+'</span>');
  });
    
  }

}
