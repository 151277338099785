import { Injectable, EventEmitter, Output } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EmitService {

  private _emitter;
  private _emitter_removegrpmember;
  public previousUrl;
  //To readjust table
  private readjustDataTableSubject: BehaviorSubject<any>;
  private readjustDataTableEvent: any;

  //To refresh table
  private refreshDataTableSubject: BehaviorSubject<any>;
  private refreshDataTableEvent: any;
  @Output() langLoginChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadDygraph: EventEmitter<any> = new EventEmitter();
  refreshWizardDataSubject: BehaviorSubject<any>;
  refreshWizardEvent: any; 
  checkInactiveInterFaceSubject: BehaviorSubject<any>;
  checkInactiveInterfaceEvent: any;
  newDistSubject = new BehaviorSubject<any>(false);
  newDistEvent = this.newDistSubject.asObservable();
  // refreshWizardDataSubjectEbill: any;
  // refreshWizardEventEbill: BehaviorSubject<any>;
  refreshWizardDataSubjectEbill:  BehaviorSubject<any>;
  langSubject: BehaviorSubject<any>;
  langEvent: any;
  refreshWizardEventEbill: any;

  inviteCodeSubject: BehaviorSubject<false>;
  inviteCodeEvent: any;

  checkTutorialDoneSubject: BehaviorSubject<false>;
  checkTutorialDoneEvent: any;

  eventForBiller: BehaviorSubject<boolean>;
  eventForBillerSub: any;
  refreshApiErroralert:  BehaviorSubject<any>;
  refreshApiErrorEvent:any;
  eBillModuleSub = new BehaviorSubject<any>(false);
  eBillModuleEvent = this.eBillModuleSub.asObservable();

  planStatus:  BehaviorSubject<any>;
  planStatusEvent:any;

  private whiteLabelSubject = new BehaviorSubject<any>(null);
  whitelabelConfig = this.whiteLabelSubject.asObservable();
  constructor() {
    this._emitter = new EventEmitter ( );
    this._emitter_removegrpmember = new EventEmitter ( );

    this.readjustDataTableSubject = new BehaviorSubject<any>({});
    this.readjustDataTableEvent = this.readjustDataTableSubject.asObservable();

    this.refreshDataTableSubject = new BehaviorSubject<any>({});
    this.refreshDataTableEvent = this.refreshDataTableSubject.asObservable();

    this.refreshWizardDataSubject = new BehaviorSubject<any>(false);
    this.refreshWizardEvent = this.refreshWizardDataSubject.asObservable();

    this.checkInactiveInterFaceSubject = new BehaviorSubject<any>(false);
    this.checkInactiveInterfaceEvent = this.checkInactiveInterFaceSubject.asObservable();

    this.refreshWizardDataSubjectEbill = new BehaviorSubject<any>(false);
    this.refreshWizardEventEbill = this.refreshWizardDataSubjectEbill.asObservable();

    this.inviteCodeSubject = new BehaviorSubject<any>(false);
    this.inviteCodeEvent = this.inviteCodeSubject.asObservable();

    this.langSubject = new BehaviorSubject<any>(false);
    this.langEvent =  this.langSubject.asObservable();

    this.checkTutorialDoneSubject = new BehaviorSubject<any>(true);
    this.checkTutorialDoneEvent = this.checkTutorialDoneSubject.asObservable();

    this.eventForBiller = new BehaviorSubject<any>({s:false,name:''});
    this.eventForBillerSub = this.eventForBiller.asObservable();

    this.refreshApiErroralert = new BehaviorSubject<any>(false);
    this.refreshApiErrorEvent = this.refreshApiErroralert.asObservable();

    this.planStatus = new BehaviorSubject<any>(false);
    this.planStatusEvent = this.planStatus.asObservable();
  }

  subscribeReadjustDataTable(next, err?, complete?){
    return this.readjustDataTableEvent.subscribe(next, err, complete);
  }

  publishReadjustDataTable(data){
    this.readjustDataTableSubject.next(data);
  }

  subscribeRefreshDataTable(next, err?, complete?){
    return this.refreshDataTableEvent.subscribe(next, err, complete);
  }

  publishRefreshDataTable(data){
    this.refreshDataTableSubject.next(data);
  }

  subscribeToServiceEmitter ( callback ) {
    return this._emitter.subscribe ( b => callback ( b ) );
  }

  removemeberfrmGrp ( callback ) {
    return this._emitter_removegrpmember.subscribe ( b => callback ( b ) );
  }
  
  removeMemberFrmGrp ( data ) {
    this._emitter_removegrpmember.emit ( data );
  }

  emitThisData ( data ) {
    this._emitter.emit ( data );
  }
  emitLoginLanguageChange ( data ) {
    this.langLoginChange.emit (data );
  }
  emitPreviosUrl(url){
    this.previousUrl=url;
  }
  emitReloadDygraph ( data ) {
    this.reloadDygraph.emit (data );
  }
  subscribeRefreshWizardE(data){
    this.refreshWizardDataSubject.next(data);
  }

  subscribeInterfaceStatus(data){
    this.checkInactiveInterFaceSubject.next(data);
  }
  subscribeRefreshWizardEbill(data){
    this.refreshWizardDataSubjectEbill.next(data);
  }
  subscribeRefreshInvitedCode(data){
    this.inviteCodeSubject.next(data);
  }
  subscribeLang(data){
    this.langSubject.next(data);
  }
  subscribeTutorialStatus(data){
    this.checkTutorialDoneSubject.next(data);
  }
  subscribeEventForEbill(data){
    this.eventForBiller.next(data);
  }
  subscribeapiError(data){
    this.refreshApiErroralert.next(data);
  }
  subscribebothPlansStatus(data){
    this.planStatus.next(data);
  }
  setWhiteLabelConfig(data){
    this.whiteLabelSubject.next(data);
    localStorage.setEncryptedItem('configData', JSON.stringify(data));
  }
}