import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeLineComponent } from '@app/shared/time-line/time-line.component';
import {I18nModule} from "@app/shared/i18n/i18n.module";

@NgModule({
  declarations: [TimeLineComponent],
  imports: [
    CommonModule,I18nModule
  ],
  exports:[TimeLineComponent]
})
export class TimeLineModule { }
