import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  apiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = localStorage.getEncryptedItem('API_END_POINT_GATE2B');
  }

  getBillerData(data){
    return this.httpClient.post(this.apiUrl+'sixAPICertificate/getBillerDetails',data);
  }
}
