import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from "@env/environment";
import { CommonService } from '@app/shared/common/common.service';
import { map } from 'rxjs/operators';
import { AppState } from '@app/app.service';
import { TutorialEventsService } from '../services/tutorial-events.service';
import { I18nService } from '@app/shared/i18n';

@Injectable()
export class RoleResolveService implements Resolve<any> {

  constructor(private http: HttpClient,private commonService: CommonService,private router: Router,private appService: AppState,private ts: TutorialEventsService,private i18nService: I18nService) { }
  resolve(): Observable<any> {
    setTimeout(() => {
      this.getTutorialData();
    },100);
    let cookieExist = this.commonService.getCookie(environment.checkCookie);
    if (cookieExist == "" || !localStorage.getEncryptedItem('partyID')) {
      localStorage.clear();
      console.log('role');
      
      window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'auth/logout/'+encodeURIComponent(this.commonService.encrypt(window.location.href))
    }
    else{
      if( !localStorage.getEncryptedItem('userData') ){
        let formData = new FormData();
        formData.append('code','EDI');
        formData.append('accId',localStorage.getEncryptedItem('accId'));
        formData.append('partyID',localStorage.getEncryptedItem('partyID'));
        let objectUser = this.commonService.createObject(formData);
        let tokenUser = this.commonService.createToken(objectUser);
        let encryptedTokenUser = this.commonService.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser);
        return this.http.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'users/user-profile',encrDataUser)
        .pipe(map((dataFromApi) => {
          let user = dataFromApi['data'];
          // if( !user[0].hasPermission || !user[0].isConfirmedParty){
          //   localStorage.clear();
          //   window.location.href = environment.LOGIN_URL[localStorage.getEncryptedItem('domain')];
          // }
          let permissions = this.commonService.encrypt(String(user[0].permissions.Allow));
          localStorage.setEncryptedItem('permissions',permissions);
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty);
          localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty);
          localStorage.setEncryptedItem('hasPermission',user[0].hasPermission);
          localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial);
        }));
      }
    }
    
  }
  getTutorialData(){
    let formData = new FormData();
    let lanId = this.commonService.getCurrentLanguageCode();
    lanId = lanId ? lanId : 'de';
    formData.append('language',lanId.toUpperCase());
    formData.append('type','tour');
    formData.append('fk_application_version','8');
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken',encryptedToken);
    this.appService.getTourListData(encrData).subscribe( data => {
      this.appService.setTourData(data["data"]);
      // localStorage.setEncryptedItem('stepContent',JSON.stringify(data["data"]));
      localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(data["data"])))));
      this.ts.chageData(true);
    })
  }
}
