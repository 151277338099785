import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-horizontal-wrapper',
  template: `
    <div class="form-group row mx-0">
      <label [attr.for]="id" class="col-sm-12 col-md-4 col-lg-4 col-form-label px-0" *ngIf="to.label">
        {{ to.label }}
        <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>
      </label>
      <div class="col-sm-12 col-md-8 col-lg-8 col-form-label px-0">
        <ng-template #fieldComponent></ng-template>
        <formly-validation-message [field]="field" *ngIf="showError" class="invalid-feedback d-block"></formly-validation-message>
      </div>
    </div>
  `,
})
export class FormlyHorizontalWrapper extends FieldWrapper {
}