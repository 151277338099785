import { NgModule } from "@angular/core";
import { ToggleActiveDirective } from "./toggle-active.directive";
import {NotificationService} from "./notification.service";

@NgModule({
  declarations: [ToggleActiveDirective],
  providers: [ NotificationService],
  
  exports: [ToggleActiveDirective]
})
export class UtilsModule {
  static forRoot(){
    return {
      ngModule: UtilsModule,
      providers: [NotificationService]
    }
  }
}
