<header id="header" [ngClass]="headerColor ? 'colorChangeTest' : '' " class="border-green theme-bg-colors-border">
    
    <div id="logo-group" class="logo-color">
        <!-- <span id="logo"></span> -->
        <span id="logo" [ngClass]="isEpost ? 'epostlogoBackGround' : '' "></span>
    </div>
    <div class="collapseMenu menu-left-item">
        <sa-collapse-menu></sa-collapse-menu>
    </div>
    <div *ngIf="setSettings">
        <button class="btn btn-primary mt-3 ml-2" title="{{'common_back_to_dashboard' | i18n}}" (click)="backToDashboard();"><i class="fas fa-home"></i><span class="header_icon_text_custom ml-3">{{'common_back_to_dashboard' | i18n}} </span></button>
    </div>
    <div *ngIf="isBexioUser" class="ml-3">
        <button class="btn btn-primary mt-3" title="{{'common_back_to_bexio' | i18n}}" (click)="backToBexio();"><i class="fas fa-hand-o-left"></i><span class="header_icon_text_custom ml-3">{{'common_back_to_bexio' | i18n}} </span></button>
    </div>
    <div *ngIf="gotoMTC" class="ml-3">
        <button class="btn btn-primary mt-3" title="{{'common_back_to_mtc' | i18n}}" (click)="backToMTC();"> <i class="fas fa-hand-o-left"></i><span class="header_icon_text_custom ml-3">{{'common_back_to_mtc' | i18n}}</span></button>
    </div>
    <!-- <div class="headCompany menu-left-item" style="width: auto;">
        <span class="company-title theme-header-text-color">{{'common_header_company' | i18n}}:</span><span class="company-name w-100 theme-header-text-color">{{company}}</span>
    </div> -->
    <div class="pull-right ">
        <div class="header-menu d-flex flex-row justfy-content-start">
            <!-- <div class="menu-item menu-item_pading">
                <a class="upgradebtn" id="addonBtn" target="_blank" [href]="addOnUrl">{{'common_buy_addon' | i18n}}</a>
            </div> -->
            <div class="menu-item menu-item_pading" *ngIf="hasAdminPermission">
                <!-- <img src="assets/img/upgrade_arrow.png" alt="Upgrade" class="upgrade-image" title="{{'common_upgrade_your_plan' | i18n}}!"> -->
                <span> <i class="fa fa-upload" aria-hidden="true" style=" font-size: 18px;padding: 10px;"></i></span>
                <div *ngIf="ediPlan"><a [href]="upgardePlanUrl" class="upgradebtn" id="button" target="_self"><span class="upgrade_plan">{{'common_upgrade_your_plan' | i18n}}!</span></a></div>
                <div *ngIf="pdftoxmlPackage"><a [href]="upgardePackageUrl" class="upgradebtn" id="button" target="_self"><span class="upgrade_plan">{{'common_upgrade_your_package' | i18n}}!</span></a></div>

            </div>
            <div class="menu-item menu-item_pading">
                <div class="text-white p-2">
                    <div class="billing-cycle model-7">
                        <!-- <span class="theme-header-text-color">{{'common_header_theme_dark' | i18n}}</span> -->
                        <span class="showDarkIcon" title="{{'common_header_theme_dark' | i18n}}"><i class="fas fa-moon darkHeaderIcon"></i></span>
                        <div class="mx-3 checkbox mb-2">
                            <input type="checkbox" id="themeStyle"  (click)="onSelectTheme($event)">
                            <label></label>
                        </div>
                        <span class="showLightIcon" title="{{'common_header_theme_light' | i18n}}"><i class="fas fa-sun lightHeaderIcon"></i></span>                       
                        <!-- <span class="theme-header-text-color">{{'common_header_theme_light' | i18n}}</span> -->
                    </div>
                </div>
            </div>
            <div class="menu-item languageCustom" title="{{'common_header_language' | i18n}}">
                <sa-language-selector></sa-language-selector>
            </div>
            <div class="menu-item" title="{{'common_fullscreen' | i18n}}" [ngClass]="fullScreenSelected?'':'menuSelected'">
                <sa-full-screen (onFullScreenSelect)="selectFullScreen($event)"></sa-full-screen>
            </div>
            <!-- <div class="menu-item" [ngClass]="activitySelected?'menuSelected':''">
                <sa-activities (onActivitySelect)="selectActivity($event)" [activityType]="'message'"></sa-activities>
            </div> -->
            <div *ngIf="gate2bSettingsPermissionAll" class="menu-item settingsMenu" [ngClass]="setSettings?'menuSelected':''" >
                <sa-setting-menu (onSettingsSelect)="onSettingsSelect($event)"></sa-setting-menu>
            </div>
         
            <!-- <div class="menu-item"  [ngClass]="notificationSelected?'menuSelected':''">
                <sa-activities (onActivitySelect)="selectActivity($event)" [activityType]="'notification'"></sa-activities>                
            </div> -->
            <div class="menu-item webcomponent-option">
                <span style="padding: 6px 20px;" class="webComponetsBorder webComponetsHoverColor">
                    <div id="webComponetsOptions" style="padding: 6px 0px 0px 0px" ></div>
                </span>
                
            </div>
            <div class="menu-item webcomponent-profile" >
                <span style="padding: 2px 20px 6px;display: flex;align-items: center" class="webComponetsHoverColor">
                    <div class="">
                        <div class="avatar">
                            <div id="webComponetsProfile"></div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="showCompanyAlert">
        <div style="display: none;" ><sa-companyApprove></sa-companyApprove></div>
    </div>
    <div *ngIf="showerrorAlert">
        <div style="display: none;" ><sa-errorAlert></sa-errorAlert></div>
    </div>
</header>

<div bsModal #newBuild="bs-modal" class="modal fade mdModal serviceModel" id="lgModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content interConnContent">
            
                  <div class="crossDiv" >
                    <span class="modal-title" id="" style="font-size: 16px;font-weight: bold;">{{'newversion_available' | i18n}}</span>
                      <div class="pull-right closeModal" (click)="newBuild.hide()">
                          <a >
                              <i class="fa fa-close crossBtn mr-4" aria-hidden="true"></i>
                          </a>
                      </div>
                  </div>
                  <div class="row  pl-4 pr-4 mr-0 mt-4">
                        <div class="pl-4" style="font-size: 14px" [innerHTML]="buildAlert"></div>                     
                        <div class="row w-100 mb-4 justify-content-end mt-4">
                            <button  class="btn-primary btnModalSave text-center floatRight" title="{{'common_clearcache_build' | i18n}}" (click)="clearCache()">{{'common_clearcache_build' | i18n}}</button>
                        </div>
                  </div>            
          </div>
      </div>
  </div>

  <div bsModal #cookieError="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}" id="myModal">
  <div class="modal-dialog modal-sl modal-dialog-centered">
      <div class="modal-content modalBodyBgColor border" style="padding: 15px !important;  width: 800px;">
      <!-- <header class="">                                        
          <div class="pull-right crossDivError">
              <a (click)="cookieError.hide();" title="{{'common_close' | i18n}}">
                  <i class="fa fa-close crossBtn" aria-hidden="true"></i>
              </a>
          </div>
      </header> -->
          <div class="modal-body modalBody p-0">
              <div class="modal-body modalBody payBody">
                <h4>{{'common_cookie_error'|i18n}}</h4>
              </div>
              <div class="MessageBoxButtonSection">
                
                <button id="bot2-Msg1" class="btn btn-default btn-sm botTempo" (click)="errorAlertOk()">{{'common_ok'|i18n}}</button>
              </div>
          </div>
      </div>
  </div>
</div>