import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
const cloneDeep = require('clone-deep');
@Injectable({
  providedIn: 'root'
})
export class BillerCertificateService {
  // private messageSector = new BehaviorSubject('');
  // private setSector = new BehaviorSubject('');
  // currentSector = this.messageSector.asObservable();
  // settSector = this.setSector.asObservable();
  @Output() messageSector: EventEmitter<any> = new EventEmitter();
  @Output() setSector: EventEmitter<any> = new EventEmitter();
  httpOptions = {
    headers: new HttpHeaders(),
  };
  accountsUrl:string;
  gate2bUrl:string;
  gate2b_api_url:string;
  xsd_api_host: string;
  admin_api_url: any;
  // formDataSelected;
  constructor(private httpClient: HttpClient) {
    this.accountsUrl = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    this.gate2b_api_url = localStorage.getEncryptedItem('API_END_POINT_GATE2B');
    this.xsd_api_host = localStorage.getEncryptedItem('API_END_POINT_GATE2B')+'gate2bAPI/';
    this.admin_api_url = localStorage.getEncryptedItem('API_END_POINT_ADMIN');
  
   }
  changeMessage(sector: any) {
    this.messageSector.emit(sector);
  }
  setMessage(sector: any) {
    this.setSector.emit(sector);
  }
  getSectorsData() {
    // return this.httpClient.get('http://192.168.2.60:6060/api/v1/sixAPI/getSectors');
    return this.httpClient.get(this.gate2b_api_url+'sixAPICertificate/getSectors');

  }
  createBiller(data){
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/createBiller',data);
    // return this.httpClient.post('http://192.168.2.60:6060/api/v1/sixAPI/createBiller',data);
  }
  updateBiller(data){
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/updateBiller',data);
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/updateBiller',data);
  }
  updateAsset(data){
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/updateAsset',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/updateAsset',data);
  }
  getBillerDetails(data){
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/getBillerDetails',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/getBillerDetails',data);

  }
  fetchAsset(data){
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/getAsset',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/getAsset',data);
  }
  fetchBiller(data){
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/getBiller',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/getBiller',data);

  }
  getDocumentFormatList(data){
    return this.httpClient.post(this.gate2b_api_url+'gate2bAPI/gate2B/post-list/document-format/',data);
  }
  downloadFile(data){
    return this.httpClient.post(this.xsd_api_host+'downloadFile',data);
  }
  getDocumentTypeList(data){
    return this.httpClient.post(this.gate2b_api_url+'gate2bAPI/gate2B/document-type', data);
  }
  getInterfaceList(netcom_id){
    return this.httpClient.post(this.gate2b_api_url+'gate2bAPI/gate2B/netcomid-interface',netcom_id);
  }  
  getCompanyDetails(data){
    return this.httpClient.post(this.admin_api_url +`admin/company-details`,data);
  }
  searchBiller(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/searchBiller',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPICertificate/searchBiller',data);

  }
  updateInfoData(data){
    return this.httpClient.post(this.gate2b_api_url+"gate2bAPI/updateInfo",data);
  }
  getInfoData(data){
    return this.httpClient.post(this.gate2b_api_url+"gate2bAPI/infoList",data);
  }
  activeBillers(data){
    return this.httpClient.post(this.gate2b_api_url+"gate2bAPI/activeBillers",data);
    // return this.httpClient.post("http://192.168.2.57:5500/api/v1/gate2bAPI/activeBillers",data);
   
  }
}
