<div class="page-footer theme-bg-color w-100">
    <!-- <div class="row"> -->

    <!-- </div> -->
    <div class="row my-2 mx-1">
        <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 iconItems">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 helpIcon">
               <div><i class="fa fa-question-circle" aria-hidden="true" style="color: white;font-size: 20x;"></i><span style="font-size: 14px;color: #4b515a;padding-left: 10px;">Help</span></div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <sa-logout></sa-logout>
            </div>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div class="row">
                    <ul class="footerItems footerTextColor">
                        <li>
                            <span class="li-footer__copy-text">© {{year}} </span>
                            <span class="footerTxt">iomarket</span>
                        </li>
                        <li class="footerContent">
                            <a [href]="accountsUrl+'termsandcondition'" target="_blank"> <span class="li-footer__copy-text">{{'common_all_terms_and_conditions' | i18n}}</span></a>

                        </li>
                        <li class="footerContent" >
                            <a [href]="accountsUrl+'privacy-policy'" target="_blank"><span class="li-footer__copy-text">{{'common_all_privacy_policy' | i18n}}</span></a>

                        </li>
                        <li class="footerContent">
                            <a [href]="accountsUrl+'legal-notices'" target="_blank"> <span class="li-footer__copy-text">{{'common_all_legal_notices' | i18n}}</span></a>

                        </li>
                    </ul>
                </div>
                <!-- <span class="txt-color-white">SmartAdmin 1.9.0 - Web Application Framework © 2017-2019</span> -->
            </div>

    
        </div>
    </div>
</div>