import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
const cloneDeep = require('clone-deep');
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private messageSector = new BehaviorSubject('');
  private setSector = new BehaviorSubject('');
  currentSector = this.messageSector.asObservable();
  settSector = this.setSector.asObservable();
  httpOptions = {
    headers: new HttpHeaders(),
  };
  accounts_api_url:string;
  gate2bUrl:string;
  gate2b_api_url:string;
  xsd_api_host: string;
  admin_api_url: any;
  // formDataSelected;
  constructor(private httpClient: HttpClient) {
    this.accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    this.gate2b_api_url= localStorage.getEncryptedItem('API_END_POINT_GATE2B');
    this.xsd_api_host = localStorage.getEncryptedItem('API_END_POINT_GATE2B')+'gate2bAPI/';
    this.admin_api_url = localStorage.getEncryptedItem('API_END_POINT_ADMIN');
   }
  changeMessage(sector: string) {
    this.messageSector.next(sector);
  }
  setMessage(sector: string) {
    this.setSector.next(sector);
  }
  getSectorsData() {
    // return this.httpClient.get('http://192.168.2.55:5000/api/v1/sixAPI/getSectors');
    return this.httpClient.get(this.gate2b_api_url+'sixAPI/getSectors');

  }
  createBiller(data){
    return this.httpClient.post(this.gate2b_api_url+'sixAPI/createBiller',data);
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPI/createBiller',data);
  }
  updateBiller(data){
    return this.httpClient.post(this.gate2b_api_url+'sixAPI/updateBiller',data);
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPI/updateBiller',data);
  }
  updateAsset(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPI/updateAsset',data);

    return this.httpClient.post(this.gate2b_api_url+'sixAPI/updateAsset',data);
  }
  getBillerDetails(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPI/getBillerDetails',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPI/getBillerDetails',data);

  }
  fetchAsset(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPI/getAsset',data);

    return this.httpClient.post(this.gate2b_api_url+'sixAPI/getAsset',data);
  }
  fetchBiller(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPI/getBiller',data);
    return this.httpClient.post(this.gate2b_api_url+'sixAPI/getBiller',data);

  }
  getDocumentFormatList(data){
    return this.httpClient.post(this.gate2b_api_url+'gate2bAPI/gate2B/post-list/document-format/',data);
  }
  downloadFile(data){
    return this.httpClient.post(this.xsd_api_host+'downloadFile',data);
  }
  getDocumentTypeList(data){
    return this.httpClient.post(this.gate2b_api_url+'gate2bAPI/gate2B/document-type', data);
  }
  getInterfaceList(netcom_id){
    return this.httpClient.post(this.gate2b_api_url+'gate2bAPI/gate2B/netcomid-interface',netcom_id);
  }  
  getCompanyDetails(data){
    return this.httpClient.post(this.admin_api_url +`admin/company-details`,data);
  }
}
