import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import { Router} from "@angular/router";
import { LayoutService } from '@app/core/services';
import { UserService } from '@app/core/services';
import { CommonService } from '@app/shared/common/common.service';
import { I18nService } from '@app/shared/i18n';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import {LayoutService as SharedLayoutService} from "@app/shared/layout/layout.service";
import { JoyrideService } from 'ngx-joyride';
import { BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { EmitService } from '@app/ts/services/emit.service';
import { AppState } from '@app/app.service';
import { TutorialEventsService } from '@app/core/services/tutorial-events.service';
import { data } from 'jquery';
import { FormBuilder } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls:['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('smCompanyapproveModal') public smCompanyapproveModal:ModalDirective;
  @ViewChild('newBuild') public newBuild:ModalDirective;
  @ViewChild('cookieError') public cookieError:ModalDirective;
  store: any;
  light: any;
  dark: any;
  loadedDarkOptions;
  loadedDarkprofile;
  loadedLightOptions;
  loadedLightprofile;
  langChangeSubscription: Subscription;
  lanId: string;
  langaugeChanged: boolean = false;
  loadWebComponentPage = false;
  company;
  activitySelected:boolean = false;
  fullScreenSelected:boolean = true;
  notificationSelected:boolean = false;
  upgardePlanUrl;
  upgardePackageUrl;

  setSettings:boolean;
  addOnUrl;
  pagePermission: any;
  gate2bSettingsPermissionAll: boolean = false;
  // gate2bSettingsPermissionEdit: boolean = false;
  // gate2bSettingsPermissionView: boolean = false;
  companyConfirmed: any;
  showCompanyAlert:boolean=false;
  loadCompanyAlert:boolean=false;
  companyAlert: any;
  isBexioUser:Boolean=false;
  gotoMTC: boolean;
  buildAlert:String ="";
  headerColor = environment.color;
  ebillTour: {};
  abcd: boolean;
  stepContent: any;
  welcome_step: any;
  ebill_welcome: any;
  interconnect_welcome: any;
  tutorailLoad: boolean;
  showerrorAlert:boolean=false;
  hasAdminPermission:boolean = false;
  updatePlanStatus: Subscription;
  plansData: any;
  pdftoxmlPackage:boolean = false;
  ediPlan:boolean = false;
  logo: any;
  isEpost;
  defaultConfig: any;
  constructor(private _fb: FormBuilder,private router: Router,private layoutService: LayoutService,private sharedLayout:SharedLayoutService, 
      private i18nService: I18nService,private commonService: CommonService,private userService:UserService,
      private readonly joyrideService: JoyrideService,private emitService: EmitService,private tutorialService: TutorialEventsService,
      private appservice:AppState, private renderer: Renderer2) {
      let backButton = window.location.href;
      this.upgardePlanUrl = localStorage.getEncryptedItem('ADMIN_URL')+'plan';
      this.addOnUrl = localStorage.getEncryptedItem('ADMIN_URL')+'buyaddon/2';
      this.upgardePackageUrl = localStorage.getEncryptedItem('ADMIN_URL')+'manageSubscription/package';
      console.log({
        'this.upgardePlanUrl' : this.upgardePlanUrl,
        'this.upgardePackageUrl': this.upgardePackageUrl
      });
      
      if(backButton.includes('settings') || backButton.includes('ebill')){
        this.setSettings=true;
      }
      else{
        this.setSettings=false;
      }
      let permissionCookie = this.commonService.getCookie(environment.permissions);
      let permissions = permissionCookie ? this.commonService.decrypt(decodeURIComponent(permissionCookie)):'';
      this.hasAdminPermission = permissions.indexOf("admin.")!=-1 ? true : false;
      this.pagePermission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
      this.gate2bSettingsPermissionAll = this.pagePermission.includes("gate2b.settings.*");
      // if( !this.gate2bSettingsPermissionAll ){
      //   this.gate2bSettingsPermissionEdit = this.pagePermission.includes("gate2b.settings.edit");
      //   this.gate2bSettingsPermissionView = this.pagePermission.includes("gate2b.settings.view")
      // }
      // else{
      //   this.gate2bSettingsPermissionEdit = true;
      //   this.gate2bSettingsPermissionView = true;
      // }

    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => 
      setTimeout(() => {
        this.onLangChange(langObj);
      },500));
      let style =  this.commonService.getCookie(this.commonService.encrypt('styleCookie'));
      let styleTheme = this.commonService.decrypt(style)
      styleTheme ? this.layoutService.onSmartSkin(JSON.parse(styleTheme)):'';
      let status;
      setTimeout(() => {
        styleTheme?JSON.parse(styleTheme).name == 'smart-style-2' ? $("#themeStyle").prop("checked",true): $("#themeStyle").prop("checked",false):'';
         
       });
       this.logo = $("body").hasClass("smart-style-1") ?  environment.LOGO.dark[localStorage.getEncryptedItem('domain')] : environment.LOGO.light[localStorage.getEncryptedItem('domain')];
       console.log('this.logo',this.logo);
       this.isEpost = localStorage.getEncryptedItem('domain').indexOf('epost') != -1 ? 'ePost' : ''; 
       console.log('this.isEpost',this.isEpost);
       

       this.emitService.newDistEvent.subscribe(data=>{
        if(data){
          this.buildAlert = this.i18nService.getTranslation('build_alert');
          this.newBuild.show();
        }
        
      })
      this.emitService.refreshApiErrorEvent.subscribe(data=>{
        // if(data)
        //   this.cookieError.show();
      })
  }
  selectActivity(data){ 
    // this.activitySelected = data;
    if(data.type=='notification'){
      this.notificationSelected = data.value;
    }
    else
      this.activitySelected = data.value;
  }
  selectFullScreen(data){
    this.fullScreenSelected = data;
  }
  onLangChange(langObj: any) {
    langObj.isHandled = true;
    this.loadedDarkOptions = false;
    this.loadedDarkprofile = false;
    this.loadedLightprofile = false;
    if( this.langaugeChanged ){
      // setTimeout(() => {
        setTimeout(() => {
          this.lanId = this.commonService.getCurrentLanguageCode();
          this.loadDarkProfile();
          this.loadDarkOptions();
        },1000);
        
      // },1000);
    }
    
    this.langaugeChanged = true;
  }
  ngOnInit() {
    this.updatePlanStatus = this.emitService.planStatus.subscribe( data => {
          console.log('0000000',data)
          this.plansData=data
          if(this.plansData == "gate2b"){
            this.ediPlan = true;
            this.pdftoxmlPackage = false;
          }else{
            this.pdftoxmlPackage = true;
            this.ediPlan = false;
        
          }
    })
    this.showerrorAlerts();
    this.companyApprove();
    localStorage.getEncryptedItem('isBexioCompany')=='true'?this.isBexioUser=true:this.isBexioUser=false;
    this.store = this.layoutService.store;
    let skins = this.store.skins;
    for (let i = 0; i < skins.length; i++) {
      if(skins[i].name == 'smart-style-1')
      this.dark = skins[i];
      if(skins[i].name == 'smart-style-2')
      this.light = skins[i];
    }
    let mtc_cookie = this.commonService.getCookie(environment.mtc_cookie);
    if( mtc_cookie != "" || mtc_cookie){
      this.gotoMTC = true;
    }
    else{
      this.gotoMTC = false;
    }
    setTimeout(() => {
      this.loadDarkOptions();
      this.loadDarkProfile();
      this.emitService.whitelabelConfig.subscribe( data => {
        if(data){
          if( Object.keys(data).length != 0){
            this.getImage();
          }
        }
      });
    }, 1000);
    this.buildAlert = this.i18nService.getTranslation('build_alert')
    this.tutorialService.cast2.subscribe(tutorial => {
      if(tutorial){
        setTimeout(() => {
          this.getTutorialData();
        }, 1000);
      }
     
    })
    this.emitService.eBillModuleEvent.subscribe(data=>{
      this.setSettings = data ? true : (window.location.href.includes('settings')? true : (window.location.href.includes('ebill')? true : false));
    })
    
  }
  
  loadDarkOptions(): void {
    if (this.loadedDarkOptions) return;
    const script = document.createElement('script');
    script.src = environment.WC_APPS;
    document.body.appendChild(script);
    this.loadedDarkOptions = true;
    const content = document.getElementById('webComponetsOptions');
    this.lanId = this.commonService.getCurrentLanguageCode();
    let optiondata: any = {isWork: true,lang:this.lanId};
    optiondata = JSON.stringify(optiondata);
    content.innerHTML = `<custom-button optiondata='${optiondata}'></custom-button>`;
    }
  loadDarkProfile(): void {
    if (this.loadedDarkprofile) return;
    const script = document.createElement('script');
    script.src = environment.WC_PROFILE;
    // script.src = 'assets/webComponent/main.js';
    document.body.appendChild(script);
    this.loadedDarkprofile = true;
    const content = document.getElementById('webComponetsProfile');
    this.lanId = this.commonService.getCurrentLanguageCode();
    // this.userService.userData().subscribe(res=>{
    //   let user = res['data']
    //   localStorage.setEncryptedItem("userData",JSON.stringify(user))
      let profileData: any = {lang: this.lanId};
      profileData = JSON.stringify(profileData);
      content.innerHTML = `<profile-button profiledata='${profileData}'></profile-button>`;
    //   this.company = user[0].companyDetails[0].legalname;
    // })
  }

  searchMobileActive = false;

  // toggleSearchMobile(){
  //   this.searchMobileActive = !this.searchMobileActive;

  //   $('body').toggleClass('search-mobile', this.searchMobileActive);
  // }

  // onSubmit() {
  //   this.router.navigate(['/miscellaneous/search']);

  // }

  // routeToProfile(){
  //   this.router.navigate(['/profile']);
  // }
  onSelectTheme(e){

    if(e.currentTarget.checked == true){
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie),this.commonService.encrypt(JSON.stringify(this.light)),10000,environment.cookieDomain,'/')
      this.layoutService.onSmartSkin(this.light)
      $('.menuIconsOption').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);
      $('.menuIcons').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);    
      this.logo = environment.LOGO.light[localStorage.getEncryptedItem('domain')]; 
      // document.getElementById("webComponetsOptionsLight").style.display = "block"
      // document.getElementById("webComponetsOptions").style.display = "none"
      // document.getElementById("webComponetsProfileLight").style.display = "block"
      // document.getElementById("webComponetsProfile").style.display = "none"
    }
    else{
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie),this.commonService.encrypt(JSON.stringify(this.dark)),10000,environment.cookieDomain,'/')
      this.layoutService.onSmartSkin(this.dark)
      $('.menuIconsOption').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      $('.menuIcons').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      this.logo = environment.LOGO.dark[localStorage.getEncryptedItem('domain')];
      // document.getElementById("webComponetsOptions").style.display = "block"
      // document.getElementById("webComponetsOptionsLight").style.display = "none"
      // document.getElementById("webComponetsProfile").style.display = "block"
      // document.getElementById("webComponetsProfileLight").style.display = "none"
    }
    this.getImage();
    const currentTheme = $("body").hasClass("smart-style-1") ? 'dark_theme' : 'light_theme';
    let config = JSON.parse(localStorage.getEncryptedItem('configData'));
    let style = config['color'];
    for (const [cssVar, themeColors] of Object.entries(style)) {
      // Set the CSS variable for the current theme
      if (themeColors[currentTheme]) {
          document.documentElement.style.setProperty(cssVar, themeColors[currentTheme]);
      }
    }
    this.loadedDarkprofile = false;
    // $('profile-button').remove();
    // this.loadDarkProfile();
    this.loadedDarkOptions = false;
    
    // $('custom-button').remove();
    // this.loadDarkOptions();
  }

  // goToSubscription() {
  //   window.open(
  //     this.upgardePlanUrl, "_blank");
  // }
  // goToBuyAddOn(){
  //   this.router.navigate(['/buyaddon/41420-0000411428-92']);
  // }
  onSettingsSelect(data){
    this.setSettings = data;
  
  }
  backToDashboard(){
     this.sharedLayout.triggerAppDataChange({ code: "common", showDefault: true});
      this.router.navigate(['/userDashboard/']);
      this.setSettings=true;
      this.emitService.eBillModuleSub.next(false);
      // this.onSettingsSelect.emit(false);
  }  
  companyApprove(){
    this.companyConfirmed  = localStorage.getEncryptedItem('isCompanyConfirmed');
    //this.companyConfirmed = false;
      if(this.companyConfirmed != 'true' && !localStorage.getEncryptedItem('companyAlert')){
      this.showCompanyAlert = true;
      localStorage.setEncryptedItem("companyAlert","true");
    }else{
      this.showCompanyAlert = false;
    }
  }
  backToBexio(){
    window.open(environment.BEXIO_URL, '_self')
    return false;
  }
  backToMTC(){
    window.open(environment.MTC_URL+'companyManagement', '_self')
    return false;
  }
  clearCache() {
    localStorage.clear();
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
    location.reload()
  }

  getTutorialData(){
    this.tutorailLoad = true;
    this.stepContent = this.appservice.getTourData();
    let stepContent = this.stepContent['INTERCONNECT'] ? this.stepContent['INTERCONNECT'] : JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['INTERCONNECT'];
    this.interconnect_welcome = stepContent.stepData['interconnect_welcome'] ? stepContent.stepData['interconnect_welcome'] : "";
    setTimeout(() => {
      this.tutorailLoad = false;
    });
  }
  showerrorAlerts(){
    this.emitService.refreshApiErroralert.subscribe(data=>{
      if(data){
        console.log(data);
        this.showerrorAlert= true 
        // location.reload()
         
      }
    });
    
  }

  errorAlertOk(){
    localStorage.clear();
    console.log('head');
    
    window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'auth/logout/'+encodeURIComponent(this.commonService.encrypt(window.location.href))
    this.cookieError.hide();
  }

  getImage() {
    const currentTheme = $("body").hasClass("smart-style-1") ? 'dark_theme' : 'light_theme';
    let imageData = this.commonService.getWhiteLabelImageData(currentTheme);
    let logo_div = document.querySelector('#logo');
    let logo_url = imageData['dataUrl'];
    this.renderer.setStyle(logo_div,'backgroundImage', `url(${logo_url})`);
  }
}
