import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  accounts_api_url:string;
  gate2b_api_url: string;
  _tourData;
  xsd_api_host: any;
  admin_api_url: any;

  constructor(private httpClient: HttpClient) {
    this.accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    this.gate2b_api_url = localStorage.getEncryptedItem('API_END_POINT_GATE2B');
    this.xsd_api_host = localStorage.getEncryptedItem('API_END_POINT_GATE2B')+'gate2bAPI/';
    this.admin_api_url = localStorage.getEncryptedItem('API_END_POINT_ADMIN');
  }
  getUserImg(accID){
    return this.httpClient.post(this.accounts_api_url+`users/fetch-image`,accID)
  }
  getCommunications(viewData){
    return this.httpClient.post<any[]>(this.gate2b_api_url+'gate2bAPI/gate2B/netcomid-interface/',viewData);
  }
  updateTutorialStatus(data){
    return this.httpClient.post(this.gate2b_api_url+"gate2bAPI/updateTutorialStatus",data).pipe(map((response:Response)=>response))

  }
  getPlanData(data){
    return this.httpClient.post(this.admin_api_url+'admin/manageSubscriptions',data);
  }
  getCounts(data){
    return this.httpClient.post(this.gate2b_api_url+"gate2bAPI/gate2B/counts",data).pipe(map((response:Response)=>response))
  }
  getEBillData(data){
    return this.httpClient.post(this.xsd_api_host+"getEbillUsedCount",data).pipe(map((response:Response)=>response))
  }
  getTourData(){
    return this._tourData;
  }
  setTourData(data){
    this._tourData = data;
  }
  getNetcomId(data) {
    return this.httpClient.post(this.xsd_api_host+"getEbillUsedCount",data).pipe(map((response:Response)=>response))
  }
}
