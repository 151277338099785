<div class="header-settings" style="width: 60px;text-align: center;height: 45px;font-size: 23px!important;" (click)="openSettingsList()">
	<span class="badge count" *ngIf="showCount">{{invitationCount}}</span>
	<div stepNumber="2" joyrideStep="settings"  class="theme-header-text-color" style="border: none; color: #fff;padding: 11px;"  title="{{'common_all_SettingMenu' | i18n}}"
		[title]="settings_step.title" [stepContent]="customContent" [counterTemplate]="counter" [stepContentParams]="settings_step" 
		[prevTemplate]="prevButton"
		[nextTemplate]="nextButton"
		[doneTemplate]="doneButton">
		<i class="theme-header-text-color glyphicon glyphicon-cog "></i>
	</div>
	<ng-template #prevButton>
		<button  class="btn btn-primary btn-save">{{'tutorial_back' | i18n}}</button>
		<!-- <my-button>Go back!</my-button> -->
	</ng-template>
	<ng-template #nextButton>
		<button  class="btn btn-primary btn-save">{{'tutorial_next' | i18n}}</button>
		<!-- <my-button>Go ahead!</my-button> -->
	</ng-template>
	<ng-template #doneButton>
		<button  class="btn btn-primary btn-save">{{'tutorial_done' | i18n}}</button>
		<!-- <my-button>Complete</my-button> -->
	</ng-template>
	<ng-template #counter >
		<button  class="btn btn-primary btn-save" (click)="saveContent()">{{'tutorial_save' | i18n }}</button>
	</ng-template>
	<ng-template #customContent>
		<div class="description_area">
			<textarea  rows="5" class="in form-control textarea-field" [id]="settings_step.label" 
				[des_id]="settings_step.cid" required="required">{{settings_step?.description}}</textarea>
			<span class="editInput" [innerHTML]="settings_step?.description"></span>
		</div>
		
	</ng-template>
</div>
<div class="settings-list dropdownOptions" >
	<div class="settings-list-items" *ngIf="settingsMenu">
		<div class="settings-list-items-contents">
			<ul class="list-group settings-list-row border-bootm-green" id="top-menu" >
				<li class="list-group-item" (click)="onClickSettings('business partner')">
					<div class="settings-item-div">
						<div class="icon-div"><i class="theme-header-text-color fa fa-users"></i></div>
						{{'settings_listmenu_businessPartnerManagement' | i18n}}
					</div>
					<span class="badge count" *ngIf="showCount">{{invitationCount}}</span>
				</li>
				<li class="list-group-item" (click)="onClickSettings('business connector')">
					<div class="icon-div"><i class="theme-header-text-color fa fa-object-group"></i></div>
					{{'settings_listmenu_businessconfiguration' | i18n}}
				</li>
				<li class="list-group-item" (click)="onClickSettings('interface')">
					<div class="icon-div"><i class="theme-header-text-color fa fa-puzzle-piece"></i></div>
					{{'settings_listmenu_interfaceManagement' | i18n}}
				</li>
				<li class="list-group-item" (click)="goTo('subscription')">
					<div class="icon-div"><i class="theme-header-text-color fa fa-shopping-cart"></i></div>
					{{'settings_listmenu_subscriptionsPricing' | i18n}}
				</li>
				<li class="list-group-item" (click)="goTo('billing')">
					<div class="icon-div"><i class="theme-header-text-color fa fa-credit-card "></i></div>
					{{'settings_listmenu_billing' | i18n}}
				</li>
				
			</ul>
			<ul class="list-group settings-list-row" id="bottom-menu">
				<li class="list-group-item" (click)="goTo('business')">
					<div class="icon-div"><i class="theme-header-text-color fa fa-building "></i></div>
					{{'settings_listmenu_businessAdministration' | i18n}}
				</li>
				<li class="list-group-item" (click)="goTo('user')">
					<div class="icon-div"><i class="theme-header-text-color fa fa-user "></i></div>
					{{'settings_listmenu_userAdministration' | i18n}}
				</li>
				<li class="list-group-item" (click)="goTo('contracts')">
					<div class="icon-div"><i class="theme-header-text-color fas fa-file-contract "></i></div>
					{{'dashboard_see_contract' | i18n}}
				</li>
			</ul>
			<!-- <div class="settings-list-row">
				<div class="settings-list-box">
					<ul class="list-group" id="top-menu">
						<li class="list-group-item">Cras justo odio</li>
						<li class="list-group-item">Cras justo odio</li>
						<li class="list-group-item">Cras justo odio</li>
						<li class="list-group-item">Cras justo odio</li>
						<li class="list-group-item">Cras justo odio</li>
					</ul>
				</div>
			</div> -->
		</div>
	</div>
</div>