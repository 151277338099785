<!-- <div id="hide-menu" class="btn-header">
        <span> <a (click)="onToggle()" title="Collapse Menu"><i
                class="fa fa-reorder"></i></a> </span>
</div> -->

<div class="hidden-md-down dropdown-icon-menu position-relative">
    <a class="header-btn btn js-waves-off p-0 m-auto" data-action="toggle" data-class="nav-function-hidden" title="{{'common_hide_navigation' | i18n}}" (click)="onToggle()">
        <!-- <i class="fa fa-reorder iconStyle"></i> -->
        <!-- <img src="assets/img/iconSvg/Path 224.svg" class="svgImageIcons" alt=""> -->
        <div class="collaspe-menu-svg"></div>
    </a>
    <ul >
        <li>
            <a class="btn js-waves-off theme-bg-color" data-action="toggle" data-class="nav-function-minify" title="{{'common_minify_navigation' | i18n}}" (click)="menuMinify()">
                <!-- <img src="assets/img/iconSvg/Path 224.svg" class="svgImageIcons" alt=""> -->
                <div class="collaspe-menu-sub-svg mt-2"></div>
            </a>
        </li>
    </ul>
</div>