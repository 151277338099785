

import {NgModule, ModuleWithProviders} from "@angular/core";
import {CommonModule} from "@angular/common";
// import {LoginInfoComponent} from "./login-info/login-info.component";
import { I18nModule } from "../i18n/i18n.module";
import { UserTicketComponent } from "./user-ticket/user-ticket.component";
import { JqueryUiModule } from "../ui/jquery-ui/jquery-ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyHorizontalWrapper } from "../dynamicform/formly/types/horizontal-Wrapper";
import { SmartadminWidgetsModule } from "../widgets/smartadmin-widgets.module";
import { SmartadminEditorsModule } from "../forms/editors/smartadmin-editors.module";
import { ClearCacheComponent } from "./clearCache/clearCache.component";
import { BootstrapModule } from "@app/shared/bootstrap.module";
import { CompanyApproveComponent } from "./companyApprove/companyApprove.component";

export function requiredMessage(err, field) {
  let translated = getTranslation("common_required_field");
  return ` ` + translated;
}

export function getTranslation(phrase) {
  let langData = localStorage.getEncryptedItem('lang_data');
  let retVal = "";
  if (langData) {
      let parsedData = JSON.parse(langData);
      retVal = parsedData && parsedData[phrase] ? parsedData[phrase] : phrase;
      return retVal;
  }
  // return phrase;
}

@NgModule({
  imports: [CommonModule,I18nModule,JqueryUiModule,ReactiveFormsModule,FormsModule,FormlyBootstrapModule,SmartadminWidgetsModule,SmartadminEditorsModule, BootstrapModule,
    
    FormlyModule.forRoot({
      wrappers: [{ name: 'form-field-horizontal', component: FormlyHorizontalWrapper }],
      validationMessages: [
        // { name: 'required', message: 'This field is required' },
        { name: 'required',message: requiredMessage },
      ]
    }),],
    
  declarations: [ClearCacheComponent,UserTicketComponent,CompanyApproveComponent],
  exports: [ClearCacheComponent,UserTicketComponent,CompanyApproveComponent]
})
export class UserModule{}
