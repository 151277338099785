<aside id="left-panel">
  <nav>
    <br>
    <div class="profile-image-box" style="margin-top: 7px;">
      <div class="gold" [ngClass]="imageData=='' || imageData==undefined?'setnavBackgroundImg':''" (click)="goToProfile()">
        <img *ngIf="imageData!='' && imageData!=undefined" [src]="imageData ? imageData:''" alt="Image"  
          class="body-image profile-pic-image" (click)="goToProfile()">
      </div>
    </div>
    <div class="text-center">
      <label class="userlabel" (click)="goToProfile()">{{userName}}</label>
    </div>
    <div class="text-center mb-4"><label class="company-label" id="help_text_btn"  (click)="goToAdmin()" [title]="companyName">{{getShortName(companyName)}}</label></div>
      <ul saSmartMenu *ngIf="!isNavLoading" class="navList" [ngClass]="module">  
      <div *ngIf="module=='ebill'" class="module">
        <img src="assets/img/iconSvg/ebill.png" alt="eBill" class="module-img">
        <span class="module-name"> eBill </span>
      </div> 
      <div *ngIf="module=='edi'" class="module">
        <img src="assets/img/iconSvg/edi.png" alt="eBill" class="module-img">
        <span class="module-name"> EDI</span>
      </div> 
      <ng-container *ngFor="let node of navigationList">
        <li *ngIf="permissions.indexOf(node.RIGHTS)!=-1"  routerLinkActive="active" [ngClass]="node.LABEL">
          <a *ngIf="node.children.length > 0" (click) = "setNavid(node.ID,node.MODULE,'')" title="{{node.LABEL |i18n}}" >
            <i class="fa fa-lg fa-fw {{node.ICON}}"></i>
            <span class="menu-item-parent menuTooltip">{{node.LABEL |i18n}}</span>
          </a>
          <a *ngIf="node.children.length === 0 && !node.IMAGE_PATH" (click) = "setNavid(node.ID,node.MODULE,'')" routerLink="{{node.MODULE}}" title="{{node.LABEL | i18n}}">
            <i class="fa fa-lg fa-fw {{node.ICON}}"></i>
            <span class="menu-item-parent menuTooltip"> {{node.LABEL |i18n}} 
              <span class="inactive-label" *ngIf="node.LABEL=='routerInterfaces' &&inactiveInterface"><i aria-hidden="true" class="fa fa-exclamation"></i></span>
            </span>
          </a>
            <a *ngIf="node.children.length === 0 && node.IMAGE_PATH && result.length!=0" (click)="setNavid(node.ID,node.MODULE,'',true)" routerLink="{{node.MODULE}}" title="{{node.LABEL | i18n}}" [ngClass]="{'ebill-nav': node.MODULE=='/ebill/'}">
            <img src="assets/img/iconSvg/ebill.png" alt="{{node.LABEL |i18n}}"  class="ebill-icon">
            <span class="menu-item-parent menuTooltip" style="padding-left: 10px;"> {{node.LABEL |i18n}} 
              <span class="inactive-label" *ngIf="node.LABEL=='routerInterfaces' &&inactiveInterface"><i aria-hidden="true" class="fa fa-exclamation"></i></span>
            </span>
          </a>
          <ul *ngIf="node.children.length > 0">
            <li *ngFor="let child of node.children" routerLinkActive="active">
              <a *ngIf="child.children.length === 0" (click) = "setNavid(child.ID,child.MODULE,'')" routerLink="{{child.MODULE}}" title="{{child.LABEL |i18n}}">{{child.LABEL |i18n}}</a>                       
            </li>
          </ul>
        </li>
      </ng-container>           
    </ul>
        
  </nav>

    <div class="navFooter">
      <div class="ac_ConnectionSection" *ngIf="this.router.url.indexOf('ebill')!=-1 && !userEbillViewPermission">
        <div class="show-transactions">
          <div class="ac_div">
            <span>{{'navigation_ebill_transaction' | i18n}}</span>
          </div>
          <div class="ac_typeDiv mb-4">
            <span  class="common_detail">{{eBillData.count}}</span>
          </div>
          <!-- <div class="ac_div">
            <span>{{'navigation_ebill_cost_per_transaction' | i18n}}</span>
          </div>
          <div class="ac_typeDiv">
            <span  class="common_detail" *ngIf="!eBillData.zeroPrice">{{currency}} {{eBillData.price | number:lanId:false}}</span>
            <span  class="common_detail" *ngIf="eBillData.zeroPrice">{{currency}} {{eBillData.zeroPrice | number:lanId:false}}</span>
          </div>
          <div class="ac_div">
            <span>{{'navigation_ebill_cost_per_error_transaction' | i18n}}</span>
          </div>
          <div class="ac_typeDiv">
            <span  class="common_detail">{{currency}} {{ebillErrorPrice | number:lanId:false}}</span>
          </div> -->
          <div class="ac_div">
            <span>{{'navigation_ebill_cost' | i18n}}</span>
          </div>
          <div class="ac_typeDiv mb-4" style="position:relative">
            <span  class="common_detail ebill_amount">{{currency}} {{((eBillData.successCount * eBillData.price)+(eBillData.errorCount * ebillErrorPrice)) | number:lanId:false}}</span>
          </div>
        </div>
        
        <div class="py-2 alignEnd pr-3" style="position: relative;padding-left: 15px;">
          <button type="submit" class="tile-formbtn  clickLbl fillGreen pull-left ebill_price_btn" title="{{'navigation_ebill_priceLink' | i18n}}" (click)="goTo()" *ngIf="hasAdminPermission">{{'navigation_ebill_priceLink' | i18n}}</button>
          <span class="transactionUnit ebill_details px-0  pull-right show-transactions-icon" >
            <img src="assets/img/iconSvg/info.png" alt="up arrow" class="nav-infoIcon  px-0 ml-2"/>
          </span>
        <div style="clear:both"></div>
        </div>
        
      </div>
      <hr class="hrColor">
        <div class="colDisplay d-flex flex-column">
          <div class="helpSection footer-menu pb-0 mb-3 pt-1" (click)="goToSupport()">
            <div>
                <div id="help_text_div" style="font-size: 14px;" class="description mb-4" title="Support">
                  <p class="mb-3">{{'support_heading' | i18n}}</p>
                  <p>{{'support_text' | i18n}}</p>

                </div>
                <!-- <i class="far fa-comment-dots" aria-hidden="true" style="font-size: 20x;"></i> -->
                <img src="assets/img/iconSvg/chat.png" alt="chat"  class="chatIcon">
                <span id="help_text" style="font-size: 14px;padding-left: 10px;" class="iom_btn nav_btn">Support</span>
                
            </div>
          </div>
          <div class="footer-menu">
            <!-- <div (click)="openPopup()" class="iom_btn mb-4 nav_btn tutorial-popup" title="{{'tutorial_title' | i18n}}">
              <i class="fa fa-question-circle" aria-hidden="true" style="font-size: 20x;cursor: pointer;" ></i>
              <span id="help_text1" title="{{'tutorial_title' | i18n}}" style="font-size: 14px;padding-left: 10px;cursor: pointer;" >
                      {{'tutorial_title' | i18n}}
              </span>
            </div> -->
            
            <sa-clearCache></sa-clearCache>
          </div>         
        </div>
    </div>
          <!-- <div stepNumber="1" joyrideStep="gate2b_welcome" [title]="welcome_step?.title"  stepPosition="center"
            [counterTemplate]="counter"
            [stepContentParams]="welcome_step" [stepContent]="customContent" [prevTemplate]="prevButton"
            [nextTemplate]="nextButton"
            [doneTemplate]="doneButton" *ngIf="abcd">
          </div>

          <div stepNumber="2" joyrideStep="ebill_welcome" [title]="ebill_welcome?.title"  stepPosition="center"
            [counterTemplate]="counter"
            [stepContentParams]="ebill_welcome" [stepContent]="customContentEbill" [prevTemplate]="prevButton"
            [nextTemplate]="nextButton"
            [doneTemplate]="doneButton" *ngIf="abcd">
          </div> -->
          
          <!-- <ng-template #prevButton>
            <button  class="btn btn-primary">{{'tutorial_back' | i18n}}</button>
          </ng-template>
          <ng-template #nextButton>
              <button  class="btn btn-primary">{{'tutorial_next' | i18n}}</button>
          </ng-template>
          <ng-template #doneButton>
              <button  class="btn btn-primary">{{'tutorial_done' | i18n}}</button>
          </ng-template>
          <ng-template #counter >
            <button  class="btn btn-primary btn-save ml-2" (click)="saveContent()" [disabled]="tutorailLoad">
              <i class="fa" [ngClass]="{' fa-spinner fa-spin': tutorailLoad}"></i>
                  {{'tutorial_save' | i18n }}
                </button>
          </ng-template> -->

          <!-- Template for edi welocme -->
          <!-- <ng-template #customContent>
            <div class="description_area">
                <textarea  rows="5" class="in form-control textarea-field" [id]="welcome_step?.label" [des_id]="welcome_step?.cid" required="required">{{welcome_step?.description}}</textarea>
                <span class="editInput" [innerHTML]="welcome_step?.description"></span>
            </div>              
          </ng-template> -->
          <!-- Template for edi welocme -->

          <!-- Template for ebill welocme -->
          <!-- <ng-template #customContentEbill>
            <div class="description_area">
                <textarea  rows="5" class="in form-control textarea-field" [id]="ebill_welcome?.label" [des_id]="ebill_welcome?.cid" required="required">{{ebill_welcome?.description}}</textarea>
                <span class="editInput" [innerHTML]="ebill_welcome?.description"></span>
            </div>
            
          </ng-template> -->
          <!-- Template for ebill welocme -->

        
</aside>
<div bsModal #tutorialModal="bs-modal" class="modal fade mdModal" id="tutorialModal" tabindex="-1" role="dialog" aria-labelledby="tutorialModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content payContent p-4">
          <div class="modal-header payHead p-2">
          
                  <!-- <i class="fa fa-exclamation fa-lg" aria-hidden="true"></i> -->
              
              <h2 class="modal-title" id="exampleModalLabel">{{'tutorial_title' | i18n}}</h2>
              <div class="crossDiv">
                  <div class="pull-right closeModal">
                      <a (click)="closeModal()">
                          <i class="fa fa-close crossBtn" aria-hidden="true"></i>
                      </a>
                  </div>
              </div>
          </div>
          <div class="p-2 modalBody payBody">            
            <div class="row w-100 mx-0 ">
              <span class="sub-label">
                {{'tutorial_select_application' | i18n}}
              </span>
              <div class="app-list">
                <div class="app-tut"  [ngClass]="!ebillPermission?'disabled nohover' : app_module=='EBILL' ? 'active' : '' ">
                  <div class="app-inner-tut ebill-tut" (click)="changeAppModule('EBILL',$event)">
                    <div class="app-img">
                      <img src="/assets/img/iconSvg/ebill.png" alt="eBill">
                    </div>
                    <div class="app-title theme-text-color">
                      <span [ngClass]="!ebillPermission?'disabled' : '' ">{{'user_dashboard_title_ebill' | i18n }}</span>
                    </div>
                  </div>                  
                </div>
                <div class="app-tut" [ngClass]="!interConnectPermission?'disabled nohover' :app_module==='INTERCONNECT'?'active':''">
                  <div class="app-inner-tut" (click)="changeAppModule('INTERCONNECT',$event)" >
                    <div class="app-img">
                      <img src="assets/img/iconSvg/Group 341@2x.png" alt="interconnector">
                    </div>
                    <div class="app-title theme-text-color">
                      <span [ngClass]="!interConnectPermission?'disabled' : '' ">{{'user_dashboard_title_interconnector' | i18n }}</span>
                    </div>
                  </div>
                </div>
                <div class="app-tut" [ngClass]="app_module==='EDI'?'active':''">
                  <div class="app-inner-tut edi-tut" (click)="changeAppModule('EDI',$event)">
                    <div class="app-img">
                      <img src="/assets/img/iconSvg/edi.png" alt="edi">
                    </div>
                    <div class="app-title theme-text-color">
                      <span>{{'user_dashboard_title_edi' | i18n }}</span>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <!-- <form [formGroup]="tutorial_select" class="tutorial_form">
                <label class="tutorial-form-label" >
                  <input type="radio" id="ebill_radio" value="ebill" formControlName="tutorial_module" (change)="changeAppModule('ebill')" >
                    <span [ngClass]="!ebillPermission?'disabled' : '' ">{{'user_dashboard_title_ebill' | i18n }}</span>
                </label>
                <label class="tutorial-form-label" >
                  <input type="radio" id="interconnect_radio" value="interconnect"  formControlName="tutorial_module" (change)="changeAppModule('interconnect')">
                    <span>
                      {{'user_dashboard_title_interconnector' | i18n }}</span>
                </label>
                <label class="tutorial-form-label">
                  <input type="radio" value="edi" id="edi_radio" formControlName="tutorial_module" (change)="changeAppModule('edi')">
                    <span>{{'user_dashboard_title_edi' | i18n }}</span>
                </label>
            </form> -->
              <div class="mt-4 pull-right" style="display: flex;gap: 5px;">
                <a class="btn-primary  btnModalSave text-center" title="{{'tutorial_end_button' | i18n}}" (click)="doneTour()" >{{'tutorial_end_button' | i18n}}</a>
                <a class="btn-primary  btnModalSave text-center" title="{{'common_cancel' | i18n}}" (click)="closeModal()" >{{'common_cancel' | i18n}}</a>
                <a class="btn-primary  btnModalSave text-center" title="{{'common_next' | i18n}}" (click)="openTutorial('click')" [ngClass]="noTour?'disabled nohover' : '' ">{{'common_next' | i18n}}</a>
              </div>
          </div>
      </div>
  </div>
</div>