import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


// import { Store } from '@ngrx/store';

// import * as fromAuth from '../store/auth';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { CommonService } from '@app/shared/common/common.service';
import { EmitService } from '@app/ts/services/emit.service';

declare var Buffer: any;
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  encryptionKey: string;
  constructor(
    private injector: Injector,
    private commonService: CommonService,
    private emitService: EmitService,
    // public store: Store<fromAuth.AuthState>
  ) {
    this.encryptionKey = environment.encryptionKey;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let hostName = "";
    try {
      hostName = new URL(request.url).host
    } catch (error) {
      
    }
    request = this.addHeader(request);
    if(hostName && environment.COOKIE_ENABLED_DOMAINS.find(domain => domain == hostName)){
      let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
      if(cookieExist) {
        cookieExist = JSON.parse(cookieExist);      
        if(localStorage.getEncryptedItem('partyID')&&localStorage.getEncryptedItem('partyID')!=cookieExist['partyID']) {
          localStorage.setEncryptedItem('partyID',cookieExist['partyID'])
          localStorage.removeEncryptedItem('userData');
          location.reload();
        }
      }
      
      return this.handleApiRequest(request, next);
    }
    
    if (request.url.search(localStorage.getEncryptedItem('API_END_POINT_GATE2B')+'gate2bAPI/gate2B') === 0) {
      return this.handleApiRequest(request, next);
    } else {
      return this.handleApi(request,next);
    }
  }

  private addHeader(req: HttpRequest<any>) {

    const logParams = {}; 
    // const formData = new FormData();
    if (req.body instanceof FormData) {
      // if()
      if (!req.body.has('partyIdLog')) {
        req.body.append('partyIdLog', localStorage.getEncryptedItem('partyID'));
      }
      if (!req.body.has('accIdLog')) {
        req.body.append('accIdLog', localStorage.getEncryptedItem('accId'));
      }
      if (!req.body.has('partyId')) {
        req.body.append('partyId', localStorage.getEncryptedItem('partyID'));
      }
      if (!req.body.has('accId')) {
        req.body.append('accId', localStorage.getEncryptedItem('accId'));
      }
      if (!req.body.has('mail_host_details')) {
        req.body.append('mail_host_details', localStorage.getEncryptedItem('domain'));
      }
      req =  req.clone({
        body: req.body
      })
    } else {
      // const formData = {}; 
      // // formData['partyId'] = localStorage.getEncryptedItem('partyID');
      // // formData['accId'] = localStorage.getEncryptedItem('accId');
      // req =  req.clone({
      //   body: {...req.body, ...formData}
      // })
    } 
    return req.clone({
      headers: new HttpHeaders(),
      withCredentials: true
    });
  }

  handleApiRequest(request, next) {
    // let request1 = request.clone({ headers: request.headers.set('withCredentals', 'true') })
    // const handler = next.handle(request).pipe(
    //   catchError((error, caught) => {
    //     if (error.status === 401 || error.status === 403) {
    //       // let currentURL = window.location.href
    //       let currentURL =environment.GATE2B_URL[localStorage.getEncryptedItem('domain')]+'userDashboard';
    //       // window.location.href=environment.LOGIN_URL[localStorage.getEncryptedItem('domain')];
    //       // window.location.href = environment.LOGOUT_URL[localStorage.getEncryptedItem('domain')]+encodeURIComponent(this.commonService.encrypt(currentURL))

    //       // this.store.dispatch(new fromAuth.LogoutAction());
    //       return throwError(error);
    //     } else {
    //       return throwError(error);
    //     }
    //   })
    // );
    const handler = next.handle(request).pipe(
      tap(res=> {
          if (res instanceof HttpResponse) {
                 if(res.body.data=='cookie_not_found'){
                  this.emitService.subscribeapiError(true);  
                 }
                                                 
          }
      }),
      catchError((error: any) => {
        if (error.status === 401 || error.status === 403) {
          // let currentURL = window.location.href
          let currentURL = localStorage.getEncryptedItem('GATE2B_URL')+'userDashboard';
          // window.location.href=environment.LOGIN_URL[localStorage.getEncryptedItem('domain')];
          window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'auth/logout/'+encodeURIComponent(this.commonService.encrypt(currentURL))

          // this.store.dispatch(new fromAuth.LogoutAction());
          return throwError(error);
        } else {
          return throwError(error);
        }
      }));
    return handler;
  }
 handleApi(request,next) {
  const handler = next.handle(request).pipe(
    tap(res=> {
        if (res instanceof HttpResponse) {  
               if(res.body.data=='cookie_not_found'){
                this.emitService.subscribeapiError(true); 
               }
                                                
        }
    }));
  return handler;
 }
}
