import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  gate2b_api_url: any;

  constructor(private httpClient: HttpClient) {
    this.gate2b_api_url = localStorage.getEncryptedItem('API_END_POINT_GATE2B')+'gate2bAPI/gate2B';
  }
  getCounts(data){
    return this.httpClient.post<any[]>(this.gate2b_api_url+'/exists',data);
  }
  getebillCounts(data){
    return this.httpClient.post<any[]>(this.gate2b_api_url+'/exists',data);
  }
}
