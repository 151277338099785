import {Component, EventEmitter, OnInit, Output} from '@angular/core';

declare var $:any;

@Component({
  selector: 'sa-full-screen',
  templateUrl: './full-screen.component.html'
})
export class FullScreenComponent implements OnInit {
  @Output() onFullScreenSelect = new EventEmitter();
  constructor() {
    var self = this;
    $(document).on('fullscreenchange mozfullscreenchange webkitfullscreenchange msfullscreenchange', function(event) {
      if (!window.screenTop && !window.screenY) {
        self.onFullScreenSelect.emit(true)
        $('body').removeClass('full-screen')
      } 
      else {
        self.onFullScreenSelect.emit(false)
      }
    });
  }

  ngOnInit() {
  }


  onToggle() {
    var $body = $('body');
    var documentMethods = {
      enter: ['requestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullscreen', 'msRequestFullscreen'],
      exit: ['cancelFullScreen', 'mozCancelFullScreen', 'webkitCancelFullScreen', 'msCancelFullScreen']
    };
    if (!$body.hasClass("full-screen")) {
      $body.addClass("full-screen");
      document.documentElement[documentMethods.enter.filter((method)=> {
        return document.documentElement[method]
      })[0]]()
    } else {
      $body.removeClass("full-screen");
      document[documentMethods.exit.filter((method)=> {
        return document[method]
      })[0]]()
    }
  }
}
